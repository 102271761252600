// extracted by mini-css-extract-plugin
export var hero = "tariffs-module--hero--PhAtC";
export var heroBackground = "tariffs-module--heroBackground--96mvT";
export var heroContent = "tariffs-module--heroContent--pxBR5";
export var heroMainPart = "tariffs-module--heroMainPart---YcuN";
export var heroTitle = "tariffs-module--heroTitle--AL9ga";
export var heroSwitcher = "tariffs-module--heroSwitcher--7ZiqL";
export var benefits = "tariffs-module--benefits--z8xgj";
export var benefitsContent = "tariffs-module--benefitsContent--C6J7m";
export var benefitsTitle = "tariffs-module--benefitsTitle--MM6UM";
export var benefitsTable = "tariffs-module--benefitsTable--SxIEW";
export var benefitsTableHead = "tariffs-module--benefitsTableHead--F0CAk";
export var benefitsTableHeadItem = "tariffs-module--benefitsTableHeadItem--Gde0e";
export var benefitsTableHeadItemTitle = "tariffs-module--benefitsTableHeadItemTitle--4mVH1";
export var benefitsTableHeadItemText = "tariffs-module--benefitsTableHeadItemText--Jmc-w";
export var benefitsTableHeadItemAction = "tariffs-module--benefitsTableHeadItemAction--3EFtI";
export var benefitsTableRows = "tariffs-module--benefitsTableRows--ESiW0";
export var benefitsTableRow = "tariffs-module--benefitsTableRow--dIH5M";
export var benefitsTableRowItem = "tariffs-module--benefitsTableRowItem--X1-wP";
export var benefitsTableRowItemTitle = "tariffs-module--benefitsTableRowItemTitle--eGFdf";
export var benefitsTableRowItemText = "tariffs-module--benefitsTableRowItemText--Hs6gL";
export var benefitsTableRowItemIcon = "tariffs-module--benefitsTableRowItemIcon--zjSHg";
export var benefitsTableRowHovered = "tariffs-module--benefitsTableRowHovered--w8kig";
export var order = "tariffs-module--order--sJzFE";
export var orderBackground = "tariffs-module--orderBackground--kiJRi";
export var orderContent = "tariffs-module--orderContent--Upbls";
export var orderContentTitle = "tariffs-module--orderContentTitle--o9Sn5";
export var orderContentDescription = "tariffs-module--orderContentDescription--s+X-r";