import React, { useEffect, useState } from 'react';
import Media from 'react-media';
import cn from 'classnames';

import IconBenefitsCheckmark from '../assets/icons/benefits-checkmark.svg';
import IconMinus from '../assets/icons/minus.svg';

import Layout from '../components/layout';
import Seo from '../components/seo';
import Section from '../components/section/section';

import TariffList from '../components/tariff-list/tariffList';

import { yaEvent } from '../helper/metrika';
import { setCookie } from '../helper/cookies';
import { getUtmQueriesFromSearch } from '../helper/utmQueries';

import * as s from '../styles/tariffs.module.scss';

const MENU_LEFT = [
  {
    title: 'Главная',
    value: '/',
    mobile: true
  },
  {
    title: 'Тарифы',
    value: '#tariffs',
    onClick: () => yaEvent('pressed_tariff_button')
  }
];

const BENEFITS = [
  {
    title: 'Объем данных',
    benefits: [
      {
        nameBenefit:
          'Неограниченное количество кандидатов/пользователей/вакансий',
        accessInBaseTariff: 'yes',
        accessInStandardTariff: 'yes'
      }
    ]
  },
  {
    title: 'Отчеты',
    benefits: [
      {
        nameBenefit: 'Воронка статусов',
        accessInBaseTariff: 'yes',
        accessInStandardTariff: 'yes'
      },
      {
        nameBenefit: 'Отчет по отказам',
        accessInBaseTariff: 'yes',
        accessInStandardTariff: 'yes'
      },
      {
        nameBenefit: 'Отчет по источникам',
        accessInBaseTariff: 'no',
        accessInStandardTariff: 'yes'
      },
      {
        nameBenefit: 'Отчет по рекрутерам',
        accessInBaseTariff: 'no',
        accessInStandardTariff: 'yes'
      }
    ]
  },
  {
    title: 'Общение с кандидатами',
    benefits: [
      {
        nameBenefit: 'Отправка писем из СберПодбора со своего почтового ящика',
        accessInBaseTariff: 'no',
        accessInStandardTariff: 'yes'
      },
      {
        nameBenefit: 'Отправка сообщений в WhatsApp',
        accessInBaseTariff: 'no',
        accessInStandardTariff: 'yes'
      }
    ]
  },
  {
    title: 'Статусы кандидатов',
    benefits: [
      {
        nameBenefit: 'Настройка статусов для всех вакансий',
        accessInBaseTariff: 'yes',
        accessInStandardTariff: 'yes'
      },
      {
        nameBenefit:
          'Настройка статусов под отдельную вакансию или группу вакансий',
        accessInBaseTariff: 'no',
        accessInStandardTariff: 'yes'
      }
    ]
  },
  {
    title: 'Cайты по трудоустройству',
    benefits: [
      {
        nameBenefit:
          'Подключение сбора откликов по вакансии c работных сайтов ',
        accessInBaseTariff: 'no',
        accessInStandardTariff: 'yes'
      },
      {
        nameBenefit:
          'Добавление кандидатов через расширение по API (HeadHunter.ru)',
        accessInBaseTariff: 'no',
        accessInStandardTariff: 'yes'
      },
      {
        nameBenefit: 'Добавление кандидатов вручную/файлом',
        accessInBaseTariff: 'yes',
        accessInStandardTariff: 'yes'
      }
    ]
  }
];

const MAIL_TO = 'mailto:sales@sberpodbor.ru';

const TariffsPage = props => {
  const { protocol, hostname, search } = props.location;

  const [isScroll, setIsScroll] = useState(false);

  const utmQueries = getUtmQueriesFromSearch(search);

  if (Object.keys(utmQueries).length) {
    setCookie('ats:utm', utmQueries, {
      expires: 365 * 10,
      domain: hostname,
      secure: protocol.indexOf('https') === 0,
      sameSite: 'strict'
    });
  }

  function onScroll() {
    setIsScroll(true);
    if (!window.pageYOffset) {
      setIsScroll(false);
    }
  }

  useEffect(() => {
    document.addEventListener('scroll', () => {
      onScroll();
    });
    return () => {
      document.removeEventListener('scroll', () => {
        onScroll();
      });
    };
  }, []);

  return (
    <Layout isScroll={isScroll} menuItems={MENU_LEFT}>
      <Seo
        description="Актуальные цены на панель для рекрутера - Сберподбор"
        title="Сберподбор - актуальная стоимость и цены на панель для рекрутера"
        lang="ru"
      />

      <Section>
        <div className={s.hero}>
          <div className={s.heroBackground} />

          <div className={s.heroContent}>
            <div className={s.heroMainPart}>
              <h1 className={cn(s.heroTitle, 'h1')}>
                Включите подходящий тариф
              </h1>
            </div>
          </div>
        </div>
      </Section>

      <Section id={'tariffs'}>
        <TariffList page="tariffs" />
      </Section>

      {/* <Section id={'benefits'} className={'noIndents'}>
         <div className={s.benefits}>
           <div className={s.benefitsContent}>
             <div className={cn(s.benefitsTitle, 'h1')}>
               Что вы получите
             </div>

             <div className={s.benefitsTable}>
               <div className={s.benefitsTableHead}>
                 <div className={s.benefitsTableHeadItem}/>

                 <div className={s.benefitsTableHeadItem}>
                   <div className={s.benefitsTableHeadItemTitle}>
                     Базовый
                   </div>
                   <Media query={'(max-width: 480px)'} defaultMatches={true}>
                     {
                       match => !match ? (
                         <div className={s.benefitsTableHeadItemText}>
                           Функционал по умолчанию доступен всем зарегистрированным пользователям
                         </div>
                       ) : null
                     }
                   </Media>
                 </div>

                 <div className={s.benefitsTableHeadItem}>
                   <div className={s.benefitsTableHeadItemTitle}>
                     Стандартный
                   </div>
                   <Media query={'(max-width: 480px)'} defaultMatches={true}>
                     {
                       match => !match ? (
                         <div className={s.benefitsTableHeadItemAction}>
                           <Button
                             onClick={() => yaEvent('pressed_submit_request')}
                             text={'Оставить заявку'}
                             href={MAIL_TO}
                             noTitle
                             newWindow
                             medium
                           />
                         </div>
                       ) : null
                     }
                   </Media>
                 </div>
               </div>

               {
                 BENEFITS.map((benefitsBlock, index) => (
                   <div className={s.benefitsTableRows} key={`block_${index}`}>

                     <div className={s.benefitsTableRow}>
                       <div className={s.benefitsTableRowItem}>
                         <div className={s.benefitsTableRowItemTitle}>
                           { benefitsBlock.title }
                         </div>
                       </div>
                       <div className={s.benefitsTableRowItem}>&nbsp;</div>
                       <div className={s.benefitsTableRowItem}>&nbsp;</div>
                     </div>

                     {
                       benefitsBlock.benefits.map((benefit, index) => (
                         <div className={cn(s.benefitsTableRow, s.benefitsTableRowHovered)} key={`item_${index}`}>
                           <div className={s.benefitsTableRowItem}>
                             <div className={s.benefitsTableRowItemText}>
                               { benefit.nameBenefit }
                             </div>
                           </div>
                           <div className={s.benefitsTableRowItem}>
                             <div className={s.benefitsTableRowItemIcon}>
                               { benefit.accessInBaseTariff === 'yes' ? <IconBenefitsCheckmark/> : <IconMinus/> }
                             </div>
                           </div>
                           <div className={s.benefitsTableRowItem}>
                             <div className={s.benefitsTableRowItemIcon}>
                               { benefit.accessInStandardTariff === 'yes' ? <IconBenefitsCheckmark/> : <IconMinus/> }
                             </div>
                           </div>
                         </div>
                       ))
                     }
                   </div>
                 ))
               }
             </div>
           </div>
         </div>
       </Section> */}

      {/* <Section id={'order'} className={'noIndents'}>
         <div className={s.order}>
           <div className={s.orderBackground} />

           <div className={s.orderContent}>
             <div className={s.orderContentTitle}>
               Уточните правила и оставьте заявку на&nbsp;приобретение тарифа
             </div>

             <div className={s.orderContentDescription}>
               Мы проведем для вас демонстрацию возможностей и ответим на все вопросы
             </div>

             <div>
               <Button
                 onClick={() => yaEvent('pressed_submit_request')}
                 text={'Оставить заявку'}
                 href={MAIL_TO}
                 noTitle
                 newWindow
                 medium
               />
             </div>
           </div>
         </div>
       </Section> */}
    </Layout>
  );
};

export default TariffsPage;
